// ========= OLD COLOR THEME START ======
// colors
$warning: #ef6c00 !default;
$warning-light: #FF8540 !default;
$grey-input: #888686 !default;
$grey-semi-dark : #6F6F6F !default;
$grey-dark: #323232 !default;
$binding-red: #FE4B4B !default;
$darkest-grey: #00112e !default;
$grey-500-old: #868c92 !default;
$blue-grey: #4d5f61 !default;
$grey-text: #7B7B7B !default;
$reload-grey: #99A0AC !default;
$pure-grey: #cccccc !default;
$table-border-grey: #dee2e6 !default;
$market-grey: #99a0ac;
$anchor-blue: #5470DE;
$grey-table-text: #5a5a5a !default;
$a0a0a0: #a0a0a0 !default;
$bg-back-grey: #EDEDEE !default;
$lightest-grey: #F6F6F6 !default;
$grey-action: #f5f5f5 !default;
$attachment: #F3FBFF !default;
$three-dor-border-color: #DBDBDB !default;
$btn-company-cancel: #F0F0F1 !default;
$email-blue: #7289E2 !default;


// ========== NEW COLORS ===========
$group-red: #EF4C44 !default;
$group-red-hover: #ca231b !default;
$group-green: #1FA95D !default;
$group-green-hover: #138e4a !default;
$expired-binding-bid-offer: #FCA70E !default;
$light-yellow: #FCF6BD !default;

// ========== theme color ===========
$form-heading: #565656 !default;
$form-heading-line: #9aa1ad !default;
$sand: #e6bb7e !default;
$market-btn: #FEB934 !default;
$turquoise: #07beaa !default;
$custom-turquoise: #61c18e !default;
$progressbar: #21d384 !default;
$light-sand: #fff8e6 !default;
$sand-dark: #e1884d !default;
$three-btn-color: #F8F8F8 !default;
$success: $progressbar;
$danger: #ec0808 !default;
$custom-danger: #ec3c61 !default;
$rosy-pink: #ff647c !default;
$dark-pink: #db75e5 !default;
$rosy-pink-light: #ffc1cb !default;
$dark-sand: #bd8c46 !default;
$sell-yellow: #F5C545 !default;
$template-highlight: #ffffcc !default;
$cornflower-blue: #5470de !default;
$hyperlink-blue: #007bff !default;
$sligh-dark-blue: #062290 !default;
$brown-grey: #7b7b7b !default;
$border-grey: #d5d5d5 !default;
$navy: #00112f !default;
$very-light-pink: #c1c1c1 !default;
$dark-sky-blue: #29bcff !default;
$a-8-b-7-c-5: #a8b7c5 !default;
$bar-behind: #e6e6e6 !default;
$f-8-f-8-f-8: #f8f8f8 !default;
$new-grey: #818181 !default;
$black: #000000 !default;
$white: #FFFFFF !default;
$slate: #4c586d !default;
$nego-btn-back: #e5e5e5 !default;
$timeblock-header: #ebf9ff !default;
$nego-send-btn: #bbc6f2 !default;
$info-icon-btn: #b4b4b4 !default;
$nego-disabled-btn: #e9ecef !default;
$nego-sec-heading: #9aa1ad !default;
$nego-light-blue: #f5fcff !default;
$nego-light-yellow: #fffbf2 !default;
$nego-counter-bid: #f1f1ff !default;
$nego-open-other: #f9f9ff !default;
$nego-accepted-green: #ddf2f0 !default;
$light-yellow-color: #fec300 !default;
$btn-gradient: #486eaf !default;

// status
$status-yellow : #eca400 !default;
$status-blue : #0e2e68 !default;
$status-grey : #a7bed3 !default;
$status-light-salmon : #f1dac4 !default;
$status-green : #61c18e !default;
$status-red : #93032e !default;
$warning-red : #BD362F !default;
$notification-bg : #e4e9f1 !default;
$notification-sup : #ee8b49 !default;
$brown : #e08240 !default;
// $box-shadow : 0 0 25px 0 rgba($black, 0.1) !default;
// $box-shadow-sm : 0 2px 8px 0 rgba($black, 0.08) !default;
// $box-shadow-lg : 0 0 25px 0 rgba($black, 0.15) !default;

// body colors
// $body-bg : linear-gradient(rgba($navy, 0.2), $white) no-repeat !default;

// Font, line-height, and color for body text, headings, and more.
// $font-family-base : "Muli",
// sans-serif;
// $font-size-base : 0.875rem;
// $h1-font-size : 3.375rem !default;
// $h2-font-size : 2.625rem !default;
// $h3-font-size : 1.5rem !default;
// $h4-font-size : 1.125rem !default;
// $h5-font-size : 1rem !default;
// $h6-font-size : $font-size-base !default;

// // Buttons + Forms
// $input-btn-focus-width : 0 !default;
// $input-btn-focus-box-shadow-sm : 0 !default;
// $input-border-color : $new-grey !default;
// $input-color : $grey-500-old;

// // spacing
// $spacer : 0.9375rem;

// // transition
// $transition : all 0.15s ease-in-out;

// // alerts
// $alert-color-level : 3 !default;
$intro-orange : #E08240 !default;
// ========= OLD COLOR THEME END ======


// ===== NEW THEME START ====
// colors
$primary-100 : #89A2FF !default;
$primary-200 : #4469FF !default;
$primary-300 : #0A3AFF !default;
$primary-400 : #0F1FD1 !default; // Main
$primary-500 : #0C18A1 !default;
$primary-600 : #081172 !default;
$primary-700 : #050A42 !default;

$secondary-100 : #E7E7EA !default;
$secondary-200 : #CECFD4 !default;
$secondary-300 : #9DA0A9 !default;
$secondary-400 : #6C707E !default;
$secondary-500 : #3B4154 !default;
$secondary-600 : #0A111D !default; // Main

$demand-100 : #B1D380 !default;
$demand-200 : #8ABE40 !default;
$demand-300 : #63A800 !default; // Main
$demand-400 : #4F8600 !default;
$demand-500 : #3B6400 !default;

$offer-100 : #FF8080 !default;
$offer-200 : #FF4040 !default;
$offer-300 : #FF0000 !default; // Main
$offer-400 : #D00000 !default;
$offer-500 : #B00000 !default;

$info-100 : #91D5E8 !default;
$info-200 : #51BFDA !default;
$info-300 : #1AAACF !default; // Main

$warning-100 : #FFCB85 !default;
$warning-200 : #FFAB3E !default;
$warning-300 : #FF9200 !default; // Main

$success-100 : #73F197 !default;
$success-200 : #36E467 !default;
$success-300 : #0FD145 !default; // Main

$danger-100 : #F1A3B7 !default;
$danger-200 : #EA7692 !default;
$danger-300 : #E3486E !default; // Main

$grey-100 : #FAFAFB !default;
$grey-200 : #E7E7EA !default;
$grey-300 : #CECFD4 !default;
$grey-400 : #9DA0A9 !default; // header
$grey-500 : #6C707E !default; // small body
$grey-600 : #3B4154 !default; // body
$grey-700 : #0A111D !default; // Main

$black : #000000 !default;
$white : #FFFFFF !default;

// body colors
$body-color : $grey-100 !default;
$headings-color : $grey-700 !default;
$shadow-color : $grey-700 !default;

// theme colors
$theme-colors: ("primary" : $primary-400,
    "primary-100" : $primary-100,
    "primary-700" : $primary-700,
    "secondary-100" : $secondary-100,
    "secondary-500" : $secondary-500,
    "secondary" : $secondary-600,
    "success" : $success-300,
    "success-100" : $success-100,
    "warning" : $warning-300,
    "warning-100" : $warning-100,
    "danger" : $danger-300,
    "danger-100" : $danger-100,
    "info" : $info-300,

    "lightest" : $grey-200,
    "lighter" : $grey-300,
    "light" : $grey-400,
    "medium" : $grey-500,
    "dark" : $grey-700,
    "white" : $white,
    "black" : $black,

    "selling" : $offer-300,
    "selling-100" : $offer-100,
    "buying" : $demand-300,
    "buying-100" : $demand-100,

    "body-color" : $grey-100) !default;

// spacing
$spacer : 1rem;

// Fonts, line-height, and color for body text, headings, and more.
$font-family-base : 'Roboto',
'Helvetica Neue',
Helvetica,
Arial,
sans-serif;
$font-size-base : 0.875rem;
$font-size-sm : $font-size-base - .0625;
$line-height-base : 1.4;
$line-height-sm : 1.4 !default;
$headings-font-family : 'Roboto',
'Helvetica Neue',
Helvetica,
Arial,
sans-serif;
$headings-line-height : 1.2;

// heading
$h1-font-size : 1.375rem !default; // 22px
$h2-font-size : 1.125rem !default; // 18px
$h3-font-size : 1.125rem !default; // 18px
$h4-font-size : 1rem !default; // 16px
$h5-font-size : 0.9375rem !default; // 15px
$h6-font-size : 0.875rem !default; // 14px

// transition
$transition : all 0.15s ease-in-out;

// Links
$link-color : $primary-400 !default;
$link-decoration : none !default;
$link-hover-color : $secondary-600 !default;
$link-hover-decoration : none !default;

// Forms
$input-btn-focus-width : 0 !default;
$input-btn-focus-box-shadow : 0 !default;

// border-radius
$border-radius : 5px !default;

// Buttons
$btn-padding-y : 0.469rem !default;
$btn-padding-x : $spacer*1.25 !default;
$btn-line-height : 1.4 !default;

$btn-padding-y-sm : 0.375rem !default;
$btn-padding-x-sm : $spacer*1.25 !default;
$btn-font-size-sm : $font-size-base - 0.125rem !default;

$btn-padding-y-lg : $spacer/2 !default;
$btn-padding-x-lg : $spacer*1.5 !default;
$btn-font-size-lg : $font-size-base+0.25rem !default;
$btn-line-height-lg : 1.5 !default;
// ===== NEW THEME END ====