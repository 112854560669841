@import "src/assets/scss/variables";

.email-content {
    .button {
        background-color: $anchor-blue;
        color: $white;
        padding: 0.5em 0.8em;
        text-decoration: none;
        border-radius: 0.3em;
        cursor: default;
        pointer-events: none;

        &:hover {
            background-color: #555;
        }
    }
}

.btn-create {
    &-offer-v2 {
        padding: 3px 0;
        font-size: 0.75rem;

        &:hover {
            background: $group-red-hover;
        }

        @media screen and (min-width: 1300px) {
            font-size: $font-size-base - 0.125rem;
        }
    }

    &-demand-v2 {
        padding: 3px 0;
        font-size: 0.75rem;

        &:hover {
            background: $group-green-hover;
        }

        @media screen and (min-width: 1300px) {
            font-size: $font-size-base - 0.125rem;
        }
    }
}

// overwrite bootstrap custom form checkbox border css color
.custom-control-label {
    &:before {
        border: rgba(134, 140, 146, 0.2) solid 1px !important;
    }
}

// countdown width
.countdown {
    &-width {
        width: 135px;
    }

    &-icon {
        width: 16px;
        height: 18px;

        svg {
            width: 16px;
            height: 18px;
        }
    }
}

// custom checkbox scss start //
/* Customize the label (the checkbox container) */
.checkbox-container {
    font-size: $font-size-base - 0.125rem;
    display: block;
    position: relative;
    padding-left: 20px;
    margin: 8px 0 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        /* When the checkbox is checked, add a blue background */
        &:checked~.checkmark {
            background-color: $darkest-grey;
        }

        /* Show the checkmark when checked */
        &:checked~.checkmark:after {
            display: block;
        }
    }

    &.white-checkbox {
        padding-right: 20px;
        margin: 4px 0 0px;

        input {

            /* When the checkbox is checked, add a blue background */
            &:checked~.checkmark {
                background-color: $light-yellow;
                border: 1px solid $light-yellow;
            }

        }

        .checkmark {
            background: $white;
            border: $white solid 1px;
            right: 0;
            left: inherit;

            &:after {
                border: solid $black;
                border-width: 0 1px 1px 0;
            }
        }
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 13px;
        width: 13px;
        border-radius: 2px;
        border: $darkest-grey solid 1px;

        /* Create the checkmark/indicator (hidden when not checked) */
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 3px;
            top: 0px;
            width: 5px;
            height: 8px;
            border: solid $white;
            border-width: 0 1px 1px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

// custom checkbox scss end

// dynamic form css start
.create-edit {
    &-row {
        margin-right: -6.1%;
        margin-left: -6.1%;
    }

    &-item {
        padding-left: 5.5%;
        padding-right: 5.5%;
    }
}

.temp-row {
    &-right {
        padding-left: 5.5%;
    }
}

// dynamic form css end


// datepicker pointer events
.bs-datepicker-body {
    .months {
        .disabled {
            pointer-events: none;
        }
    }
}

app-demand-details {
    display: block;
}

.pencil-edit {
    padding: 10px;

    svg {
        width: 15px;
        height: 15px;
    }
}

// market table css start
.market-table {
    background: theme-color("white");
    box-shadow: 0px 3px 10px rgba(theme-color(dark), 0.04), 0px 2px 30px rgba(theme-color(dark), 0.05);
    border-radius: 5px;
    overflow: hidden;
    min-height: calc(100vh - 367px); // calc(100vh - 430px); double footer
    max-height: calc(100vh - 367px); // calc(100vh - 430px); double footer
    position: relative;
    padding-right: 5px;
    padding-left: 10px;

    .non-binding {
        width: 8px; //17px;
    }

    .dotted-loader {
        background: $white;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 30px;
    }

    &.no-data {
        p {
            position: absolute;
            left: 0;
            top: calc(50% - 10px);
            text-align: center;
            width: 100%;
            margin: 0;
        }

        &::before {
            height: 40px;
        }

        &::after {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            top: 40px;
            background: theme-color("white");
        }
    }

    &::before {
        content: '';
        width: 25%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: theme-color("white");
    }

    .btn-action {
        width: 28px;
        height: 20px;

        svg {
            width: 35px;
            margin: 0 auto;
        }
    }

    .special-padding {
        padding: 8px 2px !important;
    }

    .dataTables_scrollFoot {
        .dataTables_scrollFootInner {
            width: 100% !important;
            background: white;

            table {
                width: 100% !important;
            }
        }
    }

    table.dataTable thead .sorting,
    table.dataTable thead .sorting_desc,
    table.dataTable thead .sorting_asc {
        background-position: 40px 50%;
    }

    .dataTables_scrollHead {
        .dataTables_scrollHeadInner {
            padding-right: 0 !important;
            width: 100% !important;

            .market-demand-offer {
                width: 100% !important;
            }
        }
    }

    table.dataTable tbody td {
        padding: 8px 2px;
    }

    table.dataTable thead th {
        padding-left: 2px !important;
        padding-right: 2px !important;

        @media screen and (max-width: 1250px) {
            padding-left: 5px !important;
        }

    }

    table.dataTable,
    table.dataTable th,
    table.dataTable td {
        box-sizing: content-box;
    }

    table.dataTable.no-footer {
        border-bottom: none;
    }

    .btn-market {
        width: 35%;
        height: 29px;
        padding: 5px 6px;
    }

    table.dataTable.hover tbody tr:hover,
    table.dataTable.display tbody tr:hover {
        background-color: inherit;
    }

    .table-responsive {
        height: auto !important;
    }

    .market-demand-offer {

        thead,
        tbody {
            tr {
                border-bottom: none !important;
                border-top: 0.5px solid theme-color(light);

                th,
                td {
                    vertical-align: inherit;
                }

                td {
                    font-size: $font-size-base - 0.1875rem;
                    line-height: 13px;
                    color: theme-color(secondary);

                    @media screen and (min-width: 1300px) {
                        height: 33px;
                        font-size: $font-size-base - 0.15rem;
                    }

                    @media screen and (min-width: 1400px) {
                        height: 38px;
                        font-size: $font-size-base - 0.05rem;
                    }

                    @media screen and (min-width: 1500px) {
                        height: 40px;
                        font-size: $font-size-base + 0.05rem;
                    }
                }

                th {
                    font-size: $font-size-base - 0.3125rem;
                    line-height: 11px;
                    color: theme-color(light);
                    font-weight: 400 !important;
                    border-top: none !important;
                    @media screen and (min-width: 1300px) {
                        font-size: $font-size-base - 0.15rem;
                    }

                    @media screen and (min-width: 1400px) {
                        font-size: $font-size-base - 0.05rem;
                    }

                    @media screen and (min-width: 1500px) {
                        font-size: $font-size-base + 0.05rem;
                    }

                    &:last-child {
                        @media screen and (min-width: 1300px) {
                            font-size: $font-size-base - 0.15rem;
                        }

                        @media screen and (min-width: 1400px) {
                            font-size: $font-size-base - 0.05rem;
                        }

                        @media screen and (min-width: 1500px) {
                            font-size: $font-size-base + 0.05rem;
                        }
                    }
                }

                // &:last-child {
                //     td {
                //         &:last-child {
                //             border-bottom-right-radius: 10px;
                //         }
                //     }
                // }
            }
        }

        tfoot {
            .show-other {
                font-size: $font-size-base - 0.125rem;
            }

            th,
            td {
                border-top: 0.5px solid $table-border-grey;
            }
        }

        .user-icon {
            width: 17px; // 20px;
        }

        .price-icon svg {
            width: 12px; // 20px;
            height: auto;

            @media screen and (min-width: 1300px) {
                width: 14px;
            }

            @media screen and (min-width: 1400px) {
                width: 16px;
            }

            @media screen and (min-width: 1500px) {
                width: 18px;
            }
        }

        .premium {
            &-container {
                font-size: $font-size-base - 0.25rem;

                @media screen and (min-width: 1300px) {
                    font-size: $font-size-base - 0.20rem;
                }

                @media screen and (min-width: 1400px) {
                    font-size: $font-size-base - 0.10rem;
                }

                @media screen and (min-width: 1500px) {
                    font-size: $font-size-base
                }
            }

            &-span {
                display: block;
                font-size: 7px;
                font-weight: bold;
                color: $reload-grey;
                background: rgba($reload-grey, 0.2);
                border: 1px solid $reload-grey !important;
                padding: 1px 4px;
                border-radius: 2px;

                @media screen and (min-width: 1300px) {
                    font-size: $font-size-base - 0.30rem;
                }

                @media screen and (min-width: 1400px) {
                    font-size: $font-size-base - 0.20rem;
                }

                @media screen and (min-width: 1500px) {
                    font-size: $font-size-base - 0.15rem;
                }
            }
        }
    }
}

// market table css end

// bottom loader start
// ==== lazy load list css loader start ==== //
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 24px;
    margin-left: 40%;
    top: 5% !important;

    &.btn-elipsis {
        height: 13px;
        margin-left: 0;
    }

    div {
        position: absolute;
        top: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #ccc;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
            left: 8px;
            animation: lds-ellipsis1 0.6s infinite;
        }

        &:nth-child(2) {
            left: 8px;
            animation: lds-ellipsis2 0.6s infinite;
        }

        &:nth-child(3) {
            left: 32px;
            animation: lds-ellipsis2 0.6s infinite;
        }

        &:nth-child(4) {
            left: 56px;
            animation: lds-ellipsis3 0.6s infinite;
        }
    }
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

// bottom loader end

// custom sub header for negotiation & my markets
.custom-header {
    border: none;
    font-size: 14px;
    line-height: 16px;
    color: rgba(12, 11, 26, 0.55);

    &-parent {
        height: 28px;
        padding-top: 11px;
    }

    @media screen and (max-width: 1458px) {
        font-size: 12px;
    }
}

// -------- common page header ------
.custom-page-head {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: $black;

    @media screen and (max-width: 1542px) {
        font-size: 20px;
    }
}

.characterLimit {
    right: 24px;
    bottom: 11px;
    padding: 0;
    margin: 0;
    font-size: 0.65rem;
}

.extraHeight {
    margin-bottom: 104px;
}

#olark-wrapper .olark-launch-button .olark-button-text {
    display: none !important;
}

// .custom-accordion {
//     padding: 0;
//     background: none;

//     .disable-form-control {

//         .form-control:disabled,
//         .form-control[readonly] {
//             background-color: $white;
//         }
//     }

//     .card {
//         &-body {
//             padding: 0;
//         }

//         &-header {
//             padding: 5px 12px;
//             background: none;
//             box-shadow: 0px 10px 14px rgba($black, 0.06) !important;
//             position: relative;
//             z-index: 9;
//         }
//     }

//     .heading-text {
//         color: $cornflower-blue;
//     }
// }

.demand-offer {
    &-px {
        padding: 0 5%;
    }

    &-mx {
        margin: 0 5%;
    }
}

.wid {
    &-30 {
        width: 30px;
    }

    &-190 {
        width: 190px;
    }
}

.h {
    &-30 {
        height: 30px;
    }
}

// demand offer filter multi select
.demand-offer-filter-multi-select {
    max-height: 197px;
    padding: 0;
    list-style: none;
    overflow: auto;
    margin: 0;

    .multiselect-item-checkbox {
        padding: 6px 10px;
        cursor: pointer;
        text-align: left;

        input[type="checkbox"] {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        input[type="checkbox"]+div {
            position: relative;
            padding-left: 2em;
            vertical-align: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            margin: 0;
            color: $black;

            &::before {
                box-sizing: content-box;
                content: "";
                color: #337ab7;
                position: absolute;
                top: 50%;
                left: 0;
                width: 14px;
                height: 14px;
                margin-top: -9px;
                border: 2px solid #337ab7;
                text-align: center;
                transition: all 0.4s ease;
            }

            &::after {
                box-sizing: content-box;
                content: "";
                position: absolute;
                -webkit-transform: scale(0);
                transform: scale(0);
                -webkit-transform-origin: 50%;
                transform-origin: 50%;
                transition: -webkit-transform 0.2s ease-out;
                transition: transform 0.2s ease-out;
                transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
                background-color: transparent;
                top: 50%;
                left: 4px;
                width: 8px;
                height: 3px;
                margin-top: -4px;
                border-style: solid;
                border-color: $white;
                border-width: 0 0 3px 3px;
                -o-border-image: none;
                border-image: none;
                -webkit-transform: rotate(-45deg) scale(0);
                transform: rotate(-45deg) scale(0);
            }
        }

        input[type="checkbox"]:checked+div:after {
            content: "";
            transition: -webkit-transform 0.2s ease-out;
            transition: transform 0.2s ease-out;
            transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
            -webkit-transform: rotate(-45deg) scale(1);
            transform: rotate(-45deg) scale(1);
        }

        input[type="checkbox"]:checked+div:before {
            -webkit-animation: 0.2s ease-in borderscale;
            animation: 0.2s ease-in borderscale;
            background: #337ab7;
        }
    }
}

// legend position css for demand/offer screen
.legend-pos {
    position: relative;
    display: block;

    .nav-tabs {
        width: 100%;
        position: absolute;
        top: -30px;
    }

    .nav-item {
        &.archiveTab {
            .nav-link {
                &:last-child {
                    color: $sand-dark;
                    padding: 5px 5px 5px 35px;
                }
            }

            position: absolute;
            right: 0;
            background: transparent url("/assets/img/archive.png") no-repeat 8px 4px !important;
            @extend .shadow-sm;
            border-radius: 5px;
            background-size: 20px !important;
            border: none;
            text-transform: uppercase;
        }
    }
}

.custom-disable-field {
    color: $a-8-b-7-c-5 !important;

    &::before {
        border: 1px solid $a-8-b-7-c-5 !important;
    }
}

.additional-tooltip {
    &.tooltip {
        position: fixed;
    }

    .tooltip-inner {
        min-width: 240px;
    }
}

.multi-select-dropdown-tooltip {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

// title
[data-title] {
    position: relative;
}

[data-title]:hover::before {
    content: attr(data-title);
    position: absolute;
    bottom: -26px;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 2px;
    background: $black;
    color: $white;
    font-size: 12px;
    font-family: sans-serif;
    white-space: nowrap;
}

[data-title]:hover::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 8px;
    display: inline-block;
    color: $white;
    border: 8px solid transparent;
    border-bottom: 8px solid $black;
}

.hideAppearance {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: none !important;
}

.nav-link {
    color: $grey-500-old;
}

.back-btn-container {
    font-size: $font-size-base;
    font-weight: 700;
    outline: none;
    cursor: pointer;
    background: $bg-back-grey;
    padding: 5px 15px;
    border-radius: 5px;

    .back-icon {
        width: 25px;
        height: 25px;
        fill: $cornflower-blue;
    }
}

.disable-certificate {
    background-color: $nego-disabled-btn;
    opacity: 1;
    border-radius: 5px;
    pointer-events: none;
}

.blacklist-modal {
    max-width: 560px;
}

// .invite-modal {
//     .modal-content {
//         min-height: 437px;
//     }
// }

.modal-offer-demand {
    width: 750px;
    max-width: 750px;

    .modal-content {
        box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.198946);
        border-radius: 8px;
        border: none;
    }
}

.modal-direct-buy-sell {
    width: 600px;
    max-width: 600px;

    .modal-content {
        box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.198946);
        border-radius: 8px;
        border: none;
    }
}

// loader for demand/offer modal start
.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($grey-500-old, 0.2);
    z-index: 999999999999;

    &-bar {
        display: inline-block;
        width: 5px;
        height: 35px;
        border-radius: 4px;
        animation: loading 1s ease-in-out infinite;

        &:nth-child(1) {
            background-color: $cornflower-blue;
            animation-delay: 0;
        }

        &:nth-child(2) {
            background-color: $navy;
            animation-delay: 0.09s;
        }

        &:nth-child(3) {
            background-color: $cornflower-blue;
            animation-delay: 0.18s;
        }

        &:nth-child(4) {
            background-color: $navy;
            animation-delay: 0.27s;
        }
    }
}

@keyframes loading {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(1, 2.2);
    }

    40% {
        transform: scale(1);
    }
}

// loader for demand/offer modal end

.close-btn {
    position: relative;
    z-index: 10;
}

.text-expanded-heading {
    font-size: $font-size-base + 0.125rem;
    color: $navy !important;
    font-weight: 600;
    opacity: 0.52;
}

.highlight-template {
    background-color: $template-highlight !important;

    &-checkbox {
        .custom-control-input:checked~.custom-control-label::before {
            border-color: $template-highlight !important;
            background-color: $template-highlight !important;
        }
    }

    &-multiselect {
        .multiselect-dropdown {
            background-color: $template-highlight !important;
        }
    }
}

.still-blueDot {
    position: relative;

    &::before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $navy;
        position: absolute;
        left: 7px;
        top: calc(50% - 5px);
    }
}

// progress bar
.ng-bar-placeholder {
    height: 3px !important;
}

/*
   src/styles.css (i.e. your global style)
*/
ng-progress[dir="ltr+"] .ng-meteor,
ng-progress[dir="rtl-"] .ng-meteor {
    display: none;
}

.isDisabled {
    color: currentColor;
    cursor: not-allowed !important;
    opacity: 0.5;
    text-decoration: none;
}

.coverSelect {
    position: relative;

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        background: none;
    }

    .dropdown-down {
        display: none !important;
    }

    .multiselect-dropdown {
        background: transparent !important;
    }

    .custom-multi-select .multiselect-dropdown .dropdown-btn {
        padding: 6px 0 6px 6px !important;
    }

    .add-entity-certificate .multiselect-dropdown .dropdown-btn .selected-item {
        font-size: 0.70rem;
    }
}

.min-action-height {
    min-height: calc(100vh - 68px - 56px); // calc(100vh - 68px - 106px); // double footer
    position: relative;
}

.negotiation-multiselect {
    .multiselect-dropdown {
        margin: 0 auto;
        background: $white;
        border-radius: 0.25rem;
    }

    .dropdown-list {
        .no-data {
            h5 {
                font-size: $font-size-base - 0.075rem;
            }
        }
    }
}

// ====== create demand offer page css start ====== //
.hide-select-all {
    .item1 {
        .multiselect-item-checkbox {
            display: none;
        }
    }

    .dropdown-list {
        padding-top: 0 !important;
    }
}

.button-group-li {
    width: auto;
    margin: 0;

    li {
        display: inline-block;
        margin-right: 10px;

        button {
            text-transform: uppercase;
        }

        &:last-child {
            margin: 0;
        }
    }
}

.template-heading {
    font-size: $font-size-base - 0.0625rem;
    font-weight: 600;
    color: rgba($grey-dark, 0.7);
}

.btn {
    &-save-draft {
        width: 320px;
        font-size: $font-size-base - 0.0625rem;
    }
}

.quick-creation-background {
    border: 1px solid rgba($grey-500-old, 0.2);
    border-radius: 1.25rem;
    overflow: hidden;

    button {
        border-radius: 1.25rem;
        width: 100%;
        position: relative;
        overflow: hidden;
        -webkit-transition: ease-out 0.4s;
        -moz-transition: ease-out 0.4s;
        transition: ease-out 0.4s;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 100%;
            width: 100%;
            height: 100%;
            background: $cornflower-blue;
            z-index: -1;
            -webkit-transition: ease-out 0.4s;
            -moz-transition: ease-out 0.4s;
            transition: ease-out 0.4s;
        }

        &:not(:first-child):after {
            left: auto;
            right: 100%;
        }
    }

    .active-view-mode {
        color: $white;

        &:after {
            left: 0;
        }

        &:not(:first-child):after {
            right: 0;
            left: auto;
        }
    }
}

.min-max-width {
    width: 30px;
}

.demand-close-btn {
    background: $nego-btn-back;
    color: $brown-grey;
}

.trashIcon {
    width: 18px;
    height: 18px;
}

.offer-close-btn {
    background: $nego-btn-back;
    color: $brown-grey;
}

.add-custom-term {
    .custom-control-label {
        width: 75%;

        &:before {
            top: 0.4rem;
        }

        &:after {
            top: 0.7rem;
        }
    }
}

.add-box {
    width: 34px;
    height: 34px;
    margin-top: 2px;
    margin-left: 12px;
    line-height: 18px;
    padding: 0;
}

.custom-margin {
    margin-top: -7px;
}

// ====== create demand offer page css end ====== //

.container-min-height {
    min-height: calc(100vh - 222px); // calc(100vh - 285px); for double footer
}

.dataTables_empty {
    display: none;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: none !important;
}

//------ invalid fields -----
.invalid-feedback {
    background: transparent url("/assets/img/invalid-icon.png") no-repeat 0 2px;
    background-size: 14px auto;
    padding-left: 18px;
    @extend .text-danger;
    font-size: 86%;
    display: block !important;
}

aside {
    width: 80px;
    z-index: 999 !important;
    overflow: inherit !important;
}

// google places auto complele css
.pac-container {
    z-index: 99999999;
}

// global select field color change
select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome no-repeat right $spacer center */
    appearance: none;
    // background-image: url("/assets/svg/small-triangle-down.svg") !important;
    background-repeat: no-repeat !important;
    background-position: right 1rem center !important;
    background-size: 10px auto !important;

    &.form-control {
        color: theme-color(secondary-500);

        &:disabled {
            background-image: none !important;
        }
    }
}

.rm-back-image {
    background-image: none !important;
}

.toast-container {
    z-index: 9999999999 !important;
}

.demandOfferTooltip {
    // font-size: 15px;
    font-weight: 700;
    left: 10px !important;

    .tooltip-inner {
        text-align: center !important;
        border-radius: 0;
    }

    .tooltip-arrow {
        // display: none;
    }
}

.market-btn-tooltip {
    min-width: inherit !important;
    text-transform: uppercase;
}

.tooltip {
    min-width: 200px;
    max-width: 250px;

    .tooltip-inner {
        font-weight: 500;
        color: theme-color("white") !important;
        -webkit-text-fill-color: theme-color("white") !important;
        text-align: left !important;
        background-color: rgba(theme-color(primary-700), 0.9) !important;
        padding: 0.40625rem 0.5rem !important;
    }

    &.reveal-tooltip {
        max-width: 1000px;

        .tooltip-inner {
            max-width: 1000px !important;
        }
    }

    &.binding-tooltip {
        width: auto;
        min-width: inherit !important;

        .tooltip-inner {
            max-width: 1000px !important;
        }
    }
}

// Accordion
.accordion-box-shadow {
    box-shadow: inset 0px 10px 10px -5px $bar-behind !important;
}

// custom multi select
.custom-multi-select {
    span:not(.selected-item) {
        font-size: $font-size-base - 0.125rem;
        color: $grey-input !important;
    }

    .dropdown-down {
        border-top: 6px solid $navy !important;
        border-left: 5px solid transparent !important;
        border-right: 5px solid transparent !important;
    }

    .dropdown-up {
        border-bottom: 6px solid $navy !important;
        border-left: 5px solid transparent !important;
        border-right: 5px solid transparent !important;
    }

    .dropdown-list {
        font-size: $font-size-base - 0.125rem;
        overflow: hidden;

        ul {
            max-height: 145px !important;
        }
    }

    .dropdown-btn {
        border: 1px solid rgba(173, 181, 189, 0.2) !important;
        display: flex !important;
        align-items: center;
        justify-content: space-between;

        .selected-item {
            padding: 0 16px 0 5px !important;
            max-width: 54px;
            position: relative;
            @extend .text-ellipsis;

            @media screen and (min-width: 1200px) {
                max-width: 54px;
            }

            @media screen and (min-width: 1300px) {
                max-width: 75px;
            }

            @media screen and (min-width: 1400px) {
                max-width: 110px;
            }

            @media screen and (min-width: 1500px) {
                max-width: 115px;
            }

            a {
                position: absolute;
                right: 5px;
                padding-top: 0 !important;
            }
        }
    }
}

.add-entity-certificate {
    .dropdown-btn {
        border-bottom: 1px solid $grey-500-old !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        border-radius: 0 !important;
    }
}

// data table
table.dataTable tbody thead tr:hover,
table.dataTable.display tbody thead tr:hover {
    background-color: inherit !important;
}

.table-responsive {
    height: calc(100vh - 298px);
    overflow: auto;
}

.dataTables_scrollBody table thead tr th {
    padding: 0 !important;
}

table.dataTable thead .sorting {
    padding-right: 20px !important;
}

table.dataTable tbody tr {
    background: none;
}

table.dataTable thead th,
table.dataTable tfoot th {
    color: $brown-grey;
    font-weight: 600 !important;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}

table.dataTable thead th,
table.dataTable thead td {
    padding: 0.75rem !important;
    border-bottom: none !important;
}

// map styling
.gmnoprint,
.gm-control-active {
    display: none;
}

// overwrite default modal width
.modal-lg {
    max-width: 1090px;
}

// custom-control-label checkbox
.custom-control-input:not(:disabled):active~.custom-control-label::before {
    background-color: $success;
    border-color: $success;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: $success;
}

.checkbox-label-side {
    .custom-control-input:checked~.custom-control-label::before {
        box-shadow: 0 7px 24px 0 rgba($black, 0.12);
    }

    .custom-control-label {
        padding-left: 15px;
    }
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("/assets/img/check.png");
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: $white;
    background-color: $white;
    box-shadow: 0 7px 24px 0 rgba($black, 0.12);
}

.slider:before {
    background-image: url("/assets/svg/slider-active-new.svg") !important;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

input:checked+.slider:before {
    background-image: url("/assets/svg/slider-active.svg") !important;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.accepted-slider {
    input:checked+.slider:before {
        background-image: url("/assets/svg/slider-active-green.svg") !important;
    }
}

.custom-control {
    min-height: 1.5rem;

    &-label {
        &:before {
            width: 1.5rem;
            height: 1.5rem;
        }

        &:after {
            top: 0.3rem;
            left: -1.25rem;
        }
    }
}

.custom-control-label {
    &:before {
        top: 0;
    }

    line-height: 26px;
}

// form control start
.form-control {
    font-size: $font-size-base - 0.125rem;
    border: 1px solid rgba($grey-500-old, 0.2);
}

.form-control[readonly] {
    background-color: $white;
}

input,
button,
select,
optgroup,
textarea {
    @extend .custom-shadow-1;
}

.input-group-text {
    border: 1px solid rgba($grey-500-old, 0.2);
}

// form control end

// default progress bar global styling
.progress {
    border-radius: 0;
}

// modal z-index css
.modal {
    z-index: 99999999 !important;
}

// css for tooltip main container
.info {
    position: sticky;
    display: inline-block;
    min-width: 14px;
    height: 14px;
    font-size: 12.04px;
    line-height: 14px;
    background: $info-icon-btn;
    color: $white !important;
    -webkit-text-fill-color: $white !important;
    cursor: pointer;
    bottom: 20px;
    left: 20px;
    text-align: center;
    border-radius: 50%;
    z-index: 2;
}

// default style for all tabs
.tab-container {
    .nav-tabs {
        border-bottom: none;

        .nav-item {
            text-transform: uppercase;
        }
    }

    .nav-link {
        font-size: $font-size-base - 0.0625rem;
        padding: 5px;
        border: none;

        &.active {
            color: $info;
            border-bottom: 3px solid $info;
        }

        .nav-item {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    // style for offer detail modal tabs
    app-offer-detail {
        .tab-container {
            .nav-link {
                font-size: $font-size-base + 0.25rem;
                font-weight: 300;
                padding: 10px 25px;
                border: 1px solid $f-8-f-8-f-8;
                border-top-left-radius: 0.25rem;
                border-top-right-radius: 0.25rem;
                color: $navy;

                &.active {
                    border-bottom: none;
                    color: $grey-dark;
                    background-color: $white;
                    border-color: $navy $navy $white;
                }
            }

            .nav-item {
                margin-right: 0;
            }
        }
    }
}

.demandOfferTab {
    .nav-item {
        margin-right: 0;
        background: $white;
        border: 1px solid #E7E7E7;
        box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.07);
        border-radius: 20px 0 0 20px;
        overflow: hidden;

        .nav-link {
            padding: 4px 20px;
            border-radius: 20px;
            color: $turquoise;
            -webkit-transition: ease-out 0.3s;
            transition: ease-out 0.3s;

            .bubble {
                border-radius: 100px;
                font-size: 0.6875rem;
                width: 25px;
                height: 17px;
            }

            div {
                text-transform: uppercase;
            }

            &.active {
                color: $white;
                border-bottom: none;
                background: $turquoise;
                margin-right: 5px;
                -webkit-transition: ease-out 0.3s;
                transition: ease-out 0.3s;
                height: 100%;
                display: block;
            }
        }

        &:nth-child(2) {
            border-radius: 0 20px 20px 0;
            margin-left: -5px;
            border-left: none;

            .nav-link {
                color: $rosy-pink;

                &.active {
                    color: $white !important;
                    border-bottom: none !important;
                    background: $rosy-pink;
                    margin-left: 5px;
                    margin-right: 0;
                    -webkit-transition: ease-out 0.3s;
                    transition: ease-out 0.3s;
                    height: 100%;
                    display: block;
                }
            }
        }

        &:last-child {
            margin-right: 0;
            background: inherit;
        }
    }
}

// custom radio button
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(0, 17, 47, 0.7);
    border-radius: 100%;
    background: $white;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: rgba(0, 17, 47, 0.7);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

// for direction right use class rtl in label tag
[type="radio"]:checked+label.rtl:before,
[type="radio"]:not(:checked)+label.rtl:before {
    right: 0;
    left: auto;
}

[type="radio"]:checked+label.rtl,
[type="radio"]:not(:checked)+label.rtl {
    padding-right: 28px;
    padding-left: 0;
}

[type="radio"]:checked+label.rtl:after,
[type="radio"]:not(:checked)+label.rtl:after {
    right: 4px;
    left: auto;
}

// table style 1
.table-style-1 {
    thead th {
        white-space: nowrap;
        padding-right: 15px !important;
    }

    .action-button-width {
        width: 22px;
        height: 22px;
    }

    .table {

        th,
        td {
            font-weight: 500;
        }
    }

    th {
        font-weight: 300;
    }

    td {
        font-size: $font-size-base - 0.125rem;
        color: $grey-table-text;
    }

    .collapsed-table {
        th {
            font-size: $font-size-base;
            font-weight: 300;
        }
    }
}

// expandable table
.expandtable {
    tr {
        cursor: pointer;
    }

    .inrTable {
        table {
            width: 100%;
        }

        .bg-expand {
            background: rgba($cornflower-blue, 0.03);
        }
    }
}

// form border bottom class for add company & add entity screen
.form-border-bottom {
    border-bottom: 1px solid $grey-500-old !important;
    border: none;
    padding: 0 0 5px 0;
    width: 100%;
    box-shadow: none;
    background: none;
    height: 30px;
    font-size: $font-size-base - 0.025rem;
    color: $grey-500-old;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: $grey-500-old;
        opacity: 1;
        /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $grey-500-old;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $grey-500-old;
    }
}

.custom-font {
    font-size: 16px;
}

.custom-shadow-1,
input,
button,
select,
optgroup,
textarea {
    box-shadow: none !important;
    background: $white;
}

[hidden] {
    display: none !important;
}

.min-width-250 {
    min-width: 250px;
}

img.source-image {
    display: inline-block;
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: $grey-action;
    border-color: $border-grey;
}

.padding-right-40 {
    padding-right: 35px;
}

.red-tooltip {
    .tooltip-inner {
        background: $warning-red !important;
        color: $white !important;
    }

    .arrow::before {
        display: none;
    }
}

.btn-company-cancel {
    background: $btn-company-cancel;
}

.bg-warning-red {
    background: $warning-red;
}

.cross-btn {
    background: transparent;
    border: none;
    cursor: pointer;
}

.font-12 {
    font-size: 12px;
}

// .resend-da-confirm-button {
//     border: 1px solid $anchor-blue;
//     background: $anchor-blue;
//     border-radius: 10px;
//     color: $white;
//     font-weight: 600;
// }

// .resend-da-not-confirm-button {
//     border: 1px solid $anchor-blue;
//     color: $anchor-blue;
//     background: $white;
//     border-radius: 10px;
//     font-weight: 600;
// }

// .resend-da-cancel-button {
//     border: 1px solid $grey-text;
//     color: $grey-text;
//     background: $nego-btn-back;
//     border-radius: 10px;
//     font-weight: 600;
// }

.glowing-blue-btn {
    -webkit-animation: glowing 1000ms infinite;
    -moz-animation: glowing 1000ms infinite;
    -o-animation: glowing 1000ms infinite;
    animation: glowing 1000ms infinite;
}

@-webkit-keyframes glowing {
    0% {
        background-color: #374FAF;
        -webkit-box-shadow: 0 0 3px #374FAF;
    }

    50% {
        background-color: #829AFB;
        -webkit-box-shadow: 0 0 40px #829AFB;
    }

    100% {
        background-color: #374FAF;
        -webkit-box-shadow: 0 0 3px #374FAF;
    }
}

@-moz-keyframes glowing {
    0% {
        background-color: #374FAF;
        -moz-box-shadow: 0 0 3px #374FAF;
    }

    50% {
        background-color: #829AFB;
        -moz-box-shadow: 0 0 40px #829AFB;
    }

    100% {
        background-color: #374FAF;
        -moz-box-shadow: 0 0 3px #374FAF;
    }
}

@-o-keyframes glowing {
    0% {
        background-color: #374FAF;
        box-shadow: 0 0 3px #374FAF;
    }

    50% {
        background-color: #829AFB;
        box-shadow: 0 0 40px #829AFB;
    }

    100% {
        background-color: #374FAF;
        box-shadow: 0 0 3px #374FAF;
    }
}

@keyframes glowing {
    0% {
        background-color: #374FAF;
        box-shadow: 0 0 3px #374FAF;
    }

    50% {
        background-color: #829AFB;
        box-shadow: 0 0 40px #829AFB;
    }

    100% {
        background-color: #374FAF;
        box-shadow: 0 0 3px #374FAF;
    }
}

.trading-profile {
    &-body {
        &.modal-open {
            .modal {
                overflow-y: inherit;
            }
        }
    }
    &-modal {
        max-width: 574px;
    }
    &-popover {
        z-index: 99999999;
    }
}

// ============== new styling start =============
.custom-accordion {
    padding: 0;
    background: none;

    .open-parameters {
        .disable-form-control {
            .form-control:disabled,
            .form-control[readonly] {
                background-color: $white !important;
            }
        }
    }


    .panel-open {
        .card {
            box-shadow: 0px 3px 10px rgba(theme-color(dark), 0.04), 0px 2px 30px rgba(theme-color(dark), 0.05);
            border-radius: 25px 25px 5px 5px;
        }
    }

    .card {
        border: none;

        &-body {
            padding: 0;
        }

        &-header {
            z-index: 9;
            border: none;
            background: none;
            position: relative;
            padding: 2.5px 12px;
            border-radius: 30px;
            box-shadow: 0px 3px 10px rgba(theme-color(dark), 0.04), 0px 2px 30px rgba(theme-color(dark), 0.05);
        }
    }

    .heading-text {
        color: $cornflower-blue;
    }
}

.modal-header {
    box-shadow: 0px 3px 10px rgba(theme-color(dark), 0.04), 0px 2px 30px rgba(theme-color(dark), 0.05) !important;
    border-radius: 5px;
    height: 60px;

    .btn {
        padding: 0 !important;
    }
}

.popover-body {
    color: theme-color(dark);
}
// ============== new styling end =============