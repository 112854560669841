/* You can add global styles to this file, and also import other style files */
// fonts
// @import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

// partials
@import "src/assets/scss/custom-theme";
@import "src/assets/scss/mixins";
@import "src/assets/scss/reboot";
@import "src/assets/scss/utilities";
@import "src/assets/scss/all";

// regular style toast
// @import "~ngx-toastr/toastr.css";

// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions
// @import "~ngx-toastr/toastr-bs4-alert";
// html {
//     height: 100%;
//     min-height: 100%;
//   }
// body {
//     height: 100%;
//   padding: 0;
//   margin: 0;
// }

.p-0 {
  padding-left: 0;
  padding-right: 0;
}

.pr-0 {
  padding-right: 0;
}