@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/breakpoints";

// old styling start
// .section-padding {
//   padding: $spacer * 2.5 0;

//   @include media-breakpoint-down(sm) {
//     padding: $spacer 0;
//   }
// }

// .fl-auto {
//   flex: auto;
// }

// .z-index-2 {
//   z-index: 2;
// }

// .opacity-6 {
//   opacity: 0.6;
// }

// .opacity-3 {
//   opacity: 0.3;
// }

// .height {
//   &-35 {
//     height: 35px;
//   }
// }

// // font-weight
// .font-weight {
//   &-medium {
//     font-weight: 500 !important;
//   }

//   &-600 {
//     font-weight: 600 !important;
//   }

//   &-700 {
//     font-weight: 700 !important;
//   }

//   &-300 {
//     font-weight: 300 !important;
//   }

//   &-400 {
//     font-weight: 400 !important;
//   }
// }

// .rounded {
//   &-8 {
//     border-radius: 8px;
//   }
// }

// .elipsis {
//   text-overflow: ellipsis;
//   overflow: hidden;
//   white-space: nowrap;
//   display: block;
// }

// .display-none {
//   display: none !important;
// }

// // font-size
// .font-size {
//   &-sm {
//     font-size: $font-size-base/1.10;
//   }

//   &-md {
//     font-size: $font-size-base !important;
//   }

//   &-lg {
//     font-size: $h3-font-size;
//   }
// }

// // modal size
// .modal {
//   &-xl {
//     max-width: 1200px;
//   }

//   &-lg {
//     max-width: 992px;
//   }

//   &-md {
//     max-width: 768px;
//   }

//   &-sm {
//     max-width: 576px;
//   }

//   &-xs {
//     max-width: 360px;
//   }
// }

// .background-none {
//   background: none;
// }

// // object-fit
// .object-fit {
//   &-contain {
//     object-fit: contain;
//   }
// }

// // flex
// .flex {
//   &-1 {
//     flex: 1;
//   }

//   &-none {
//     flex: none;
//   }
// }

// // cursor
// .cursor {
//   &-inherit {
//     cursor: inherit !important;
//   }

//   &-pointer {
//     cursor: pointer !important;
//   }
// }

// // break-word
// .break-word {
//   word-wrap: break-word;
// }

// // white-space
// .white-space {
//   &-nowrap {
//     white-space: nowrap;
//   }
// }

// .bg {
//   &-form-heading {
//     background: $form-heading-line;
//     margin-bottom: 20px;
//   }

//   &-primary-gradient {
//     background-image: linear-gradient(to right,
//         $cornflower-blue 0%,
//         $cornflower-blue 100%);
//     /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//   }

//   &-primary {
//     background: $navy !important;
//   }

//   &-yellow {
//     background: $sand;
//   }

//   &-light-yellow {
//     background: $light-sand !important;
//   }

//   &-dark-yellow {
//     background: $market-btn !important;
//   }

//   &-light-yellow-clr {
//     background: $light-yellow-color !important;
//   }

//   &-danger {
//     background: $danger;
//   }

//   &-blue {
//     background: $cornflower-blue;
//   }

//   &-black {
//     background: $black;
//   }

//   &-medium {
//     background-color: $grey-500-old !important;
//   }

//   &-grey {
//     background-color: $f-8-f-8-f-8 !important;
//   }

//   &-btn-grey {
//     background-color: $reload-grey !important;
//   }

//   &-back-grey {
//     background-color: $bg-back-grey !important;
//   }

//   &-lightest-grey {
//     background-color: $lightest-grey !important;
//   }

//   &-sell-yellow {
//     background-color: $sell-yellow !important;
//   }

//   &-warning-light {
//     background-color: $warning-light !important;
//   }

//   &-offer-sell {
//     background-color: $group-red !important;
//   }

//   &-demand-buy {
//     background-color: $group-green !important;
//   }

//   &-grey-action {
//     background-color: $grey-action !important;
//   }

//   &-white {
//     background-color: $white !important;
//   }

//   &-light-grren {
//     background-color: rgba($success, 0.03);
//   }

//   &-green {
//     background: $progressbar;
//   }

//   &-template {
//     background: $template-highlight !important;
//   }

//   &-blue-shade-2 {
//     background: $nego-counter-bid !important;
//   }

//   &-blue-shade-1 {
//     background: $nego-open-other !important;
//   }

//   &-green-shade-1 {
//     background: $nego-accepted-green !important;
//   }

//   &-turquoise {
//     background: $turquoise;
//   }
// }

// .text {
//   &-binding {
//     color: $warning-red !important;
//   }

//   &-dark {
//     color: $navy !important;
//   }

//   &-green {
//     color: $turquoise !important;
//   }

//   &-group-green {
//     color: $group-green !important;
//   }

//   &-expired-binding-bid-offer {
//     color: $expired-binding-bid-offer !important;
//   }

//   &-danger {
//     color: $rosy-pink !important;
//   }

//   &-yellow {
//     color: $sand !important;
//   }

//   &-light-yellow {
//     color: $light-sand !important;
//   }

//   &-blue {
//     color: $cornflower-blue !important;
//   }

//   &-black {
//     color: $black !important;
//   }

//   &-white {
//     color: $white !important;
//   }

//   &-grey {
//     color: $grey-500-old !important;
//   }

//   &-pure-grey {
//     color: $pure-grey !important;
//   }

//   &-reload-grey {
//     color: $reload-grey !important;
//   }

//   &-underline {
//     text-decoration: underline;
//   }

//   &-ellipsis {
//     text-overflow: ellipsis;
//     overflow: hidden;
//     white-space: nowrap;
//   }

//   &-dark-sky-blue {
//     color: $dark-sky-blue;
//   }

//   &-uppercase {
//     text-transform: uppercase;
//   }

//   &-capitalize {
//     text-transform: capitalize;
//   }

//   &-turquoise {
//     color: $turquoise;
//   }

//   &-break-all {
//     word-break: break-all;
//   }
// }

// .datepicker-icon {
//   cursor: pointer;

//   img {
//     max-width: 15px;
//   }
// }

// .navi-color {
//   color: $navy !important;
//   -webkit-text-fill-color: $navy !important;
// }

// .grey {
//   &-color {
//     color: $new-grey !important;
//   }

//   &-brown {
//     color: $brown-grey !important;
//   }

//   &-dark {
//     color: $grey-dark !important;
//   }
// }

// .border {
//   border-color: $bar-behind !important;

//   &-dotted {
//     border: 2px dotted $new-grey;
//   }

//   &-yellow {
//     border: 1px solid $light-yellow-color;
//   }

//   &-bottom-dark {
//     border-bottom: 1px solid $grey-500-old !important;
//   }
// }

// .cursor {
//   &-pointor {
//     cursor: pointer;
//   }
// }

// .w {
//   &-15 {
//     min-width: 15px;
//     height: 15px;
//   }

//   &-20 {
//     min-width: 20px;
//     height: 20px;
//   }

//   &-30 {
//     min-width: 30px;
//   }

//   &-11 {
//     min-width: 11px;
//     height: 11px;
//   }

//   &-100 {
//     min-width: 100px;
//   }

//   &-220 {
//     width: 220px;
//     padding: 11px 25px 0;
//   }

//   &-130 {
//     width: 130px;
//   }

//   &-300 {
//     width: 300px;
//   }

//   &-99 {
//     width: 99px;
//   }
// }

// // max input width
// .input-max {
//   &-w-50 {
//     max-width: 50px;
//   }

//   &-w-60 {
//     max-width: 60px;
//   }

//   &-w-80 {
//     max-width: 80px;
//   }

//   &-w-100 {
//     max-width: 100px;
//   }

//   &-w-130 {
//     max-width: 130px;
//   }
// }

// .table.no-border tr td,
// .table.no-border tr th {
//   border-width: 0;
// }

// .custom-shadow {
//   &-1 {
//     box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.02);
//   }

//   &-2 {
//     box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.12);
//   }
// }

// .disable-btn {
//   opacity: 0.2 !important;
//   cursor: not-allowed;
// }

// .btn {
//   &-green {
//     background: $custom-turquoise;
//   }

//   &-yellow {
//     background: $sand;
//   }

//   &-custom-danger {
//     background: $custom-danger;
//   }

//   &-anchor {
//     background: none;
//     border: none;
//   }

//   &-save-as-temp {
//     min-width: 156px;
//   }
// }

// .font {
//   &-12 {
//     font-size: $font-size-base - 0.125rem;
//   }

//   &-10 {
//     font-size: $font-size-base - 0.25rem;
//   }
// }

// // Status classes
// .status {
//   &-yellow {
//     background: $status-yellow;
//   }

//   &-blue {
//     background: $status-blue;
//   }

//   &-grey {
//     background: $status-grey;
//   }

//   &-light-salmon {
//     background: $status-light-salmon;
//   }

//   &-green {
//     background: $status-green;
//   }

//   &-red {
//     background: $status-red;
//   }
// }

// .hocLink {
//   color: $hyperlink-blue;
//   text-decoration: underline;
// }

// /* Scrollbar styling */

// /* width */
// ::-webkit-scrollbar {
//   width: 8px;
//   height: 8px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   // box-shadow: inset 0 0 1px grey;
//   border-radius: 5px;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: rgba($table-border-grey, 1); // $white;
//   border-radius: 5px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: $grey-semi-dark;
// }

// .introjs-tooltipReferenceLayer {
//   z-index: 99999999 !important;
// }

// ============ old styling end ================

// ============ new styling start ==============
.section-padding {
  padding: $spacer * 2.5 0;

  @include media-breakpoint-down(sm) {
    padding: $spacer 0;
  }
}

.fl-auto {
  flex: auto;
}

.z-index {
  &-1 {
    z-index: 1;
  }

  &-2 {
    z-index: 2;
  }

  &-3 {
    z-index: 3;
  }

  &-4 {
    z-index: 4;
  }
}

.opacity {
  &-6 {
    opacity: 0.6;
  }

  &-3 {
    opacity: 0.3;
  }
}

.height {

  // ==== OLD STYLING START =====
  &-35 {
    height: 35px;
  }

  // ==== OLD STYLING END =====
  &-36 {
    height: 36px;
  }
}

// font-weight
.font-weight {
  &-300 {
    font-weight: 300 !important;
  }

  &-400 {
    font-weight: 400 !important;
  }

  // ===== OLD CODE START =====
  &-medium {
    font-weight: 500 !important;
  }

  // ===== OLD CODE END =====

  &-500 {
    font-weight: 500 !important;
  }

  &-600 {
    font-weight: 600 !important;
  }

  &-700 {
    font-weight: 700 !important;
  }
}

.rounded {
  &-8 {
    border-radius: 8px;
  }

  &-50 {
    border-radius: 50px;
  }
}

.elipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.display-none {
  display: none !important;
}

// font-size
.font-size {
  &-xs {
    font-size: $font-size-base - 0.1875rem; // 11px
  }

  &-sm {
    font-size: $font-size-base/1.16; // 12px
  }

  &-md {
    font-size: $font-size-base !important; // 14px
  }

  &-lg {
    font-size: $font-size-base + 0.125rem !important; // 16px
  }

  &-xl {
    font-size: $font-size-base + 0.25rem !important; // 18px
  }
}

// modal size
.modal {
  &-xl {
    max-width: 1200px;
  }

  &-lg {
    max-width: 992px;
  }

  &-md {
    max-width: 768px;
  }

  &-sm {
    max-width: 576px;
  }

  &-xs {
    max-width: 360px;
  }
}

.background-none {
  background: none;
}

// object-fit
.object-fit {
  &-contain {
    object-fit: contain;
  }
}

// flex
.flex {
  &-1 {
    flex: 1;
  }

  &-none {
    flex: none;
  }
}

// cursor
.cursor {
  &-inherit {
    cursor: inherit !important;
  }

  &-pointer {
    cursor: pointer !important;
  }
}

// break-word
.break-word {
  word-wrap: break-word;
}

// white-space
.white-space {
  &-nowrap {
    white-space: nowrap;
  }
}

.bg {
  &-primary {
    background: theme-color(primary) !important;
  }

  &-danger {
    background: theme-color(danger);
  }

  &-black {
    background: theme-color(black);
  }

  &-white {
    background-color: theme-color('white') !important;
  }

  &-offer {
    background: theme-color(selling) !important;
  }

  &-demand {
    background: theme-color(buying) !important;
  }

  // ===== OLD CODE START =====
  &-form-heading {
    background: $form-heading-line;
    margin-bottom: 20px;
  }

  &-primary-gradient {
    background-image: linear-gradient(to right,
        $cornflower-blue 0%,
        $cornflower-blue 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  &-light-yellow {
    background: $light-sand !important;
  }

  &-blue {
    background: $cornflower-blue;
  }

  &-grey {
    background-color: $f-8-f-8-f-8 !important;
  }

  &-nego-grey {
    background-color: $nego-disabled-btn !important;
  }

  &-btn-grey {
    background-color: $reload-grey !important;
  }

  &-back-grey {
    background-color: $bg-back-grey !important;
  }

  &-warning-light {
    background-color: $warning-light !important;
  }

  &-offer-sell {
    background-color: $group-red !important;
  }

  &-demand-buy {
    background-color: $group-green !important;
  }

  &-grey-action {
    background-color: $grey-action !important;
  }

  &-green {
    background: $progressbar;
  }

  &-template {
    background: $template-highlight !important;
  }

  &-blue-shade-2 {
    background: $nego-counter-bid !important;
  }

  &-blue-shade-1 {
    background: $nego-open-other !important;
  }

  &-green-shade-1 {
    background: $nego-accepted-green !important;
  }

  &-turquoise {
    background: $turquoise;
  }

  // ===== OLD CODE END =====
}

.text {
  &-binding {
    color: theme-color(danger) !important;
  }

  &-dark {
    color: $secondary-600 !important;
  }

  &-green {
    color: $turquoise !important;
  }

  &-group-green {
    color: $group-green !important;
  }

  &-expired-binding-bid-offer {
    color: $expired-binding-bid-offer !important;
  }

  &-danger {
    color: $rosy-pink !important;
  }

  &-yellow {
    color: $sand !important;
  }

  &-light-yellow {
    color: $light-sand !important;
  }

  &-blue {
    color: $cornflower-blue !important;
  }

  &-black {
    color: $black !important;
  }

  &-white {
    color: $white !important;
  }

  &-grey {
    color: $grey-500-old !important;
  }

  &-pure-grey {
    color: $pure-grey !important;
  }

  &-reload-grey {
    color: $reload-grey !important;
  }

  &-underline {
    text-decoration: underline;
  }

  &-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &-dark-sky-blue {
    color: $dark-sky-blue;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-capitalize {
    text-transform: capitalize;
  }

  &-turquoise {
    color: $turquoise;
  }

  &-break-all {
    word-break: break-all;
  }

  &-offer {
    color: theme-color(selling) !important;
  }

  &-demand {
    color: theme-color(buying) !important;
  }

  &-primary {
    color: theme-color(primary) !important;
  }
}

.datepicker-icon {
  cursor: pointer;

  img {
    max-width: 15px;
  }
}

.navi-color {
  color: $navy !important;
  -webkit-text-fill-color: $navy !important;
}

.grey {
  &-color {
    color: $new-grey !important;
  }

  &-brown {
    color: $brown-grey !important;
  }

  &-dark {
    color: $grey-dark !important;
  }
}

.border {
  border-color: $bar-behind !important;

  &-dotted {
    border: 2px dotted $new-grey;
  }

  &-yellow {
    border: 1px solid $light-yellow-color;
  }

  &-bottom-dark {
    border-bottom: 1px solid $grey-500-old !important;
  }

  &-offer {
    border: 1px solid theme-color(selling) !important;
  }

  &-demand {
    border: 1px solid theme-color(buying) !important;
  }
}

.cursor {
  &-pointor {
    cursor: pointer;
  }
}

.w {
  &-15 {
    min-width: 15px;
    height: 15px;
  }

  &-20 {
    min-width: 20px;
    height: 20px;
  }

  &-30 {
    min-width: 30px;
  }

  &-11 {
    min-width: 11px;
    height: 11px;
  }

  &-100 {
    min-width: 100px;
  }

  &-220 {
    width: 220px;
    padding: 11px 25px 0;
  }

  &-130 {
    width: 130px;
  }

  &-300 {
    width: 300px;
  }

  &-99 {
    width: 99px;
  }
}

// max input width
.input-max {
  &-w-50 {
    max-width: 50px;
  }

  &-w-60 {
    max-width: 60px;
  }

  &-w-80 {
    max-width: 80px;
  }

  &-w-100 {
    max-width: 100px;
  }

  &-w-130 {
    max-width: 130px;
  }
}

.table.no-border tr td,
.table.no-border tr th {
  border-width: 0;
}

.custom-shadow {
  &-1 {
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.02);
  }

  &-2 {
    box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.12);
  }
}

.disable-btn {
  opacity: 0.2 !important;
  cursor: not-allowed;
}

.btn {
  &-green {
    background: $custom-turquoise;
  }

  &-yellow {
    background: $sand;
  }

  &-custom-danger {
    background: $custom-danger;
  }

  &-anchor {
    background: none;
    border: none;
  }

  &-save-as-temp {
    min-width: 156px;
  }

  &-default {
    color: theme-color(dark);
  }
}

.font {
  &-12 {
    font-size: $font-size-base - 0.125rem;
  }

  &-10 {
    font-size: $font-size-base - 0.25rem;
  }
}

// Status classes
.status {
  &-yellow {
    background: $status-yellow;
  }

  &-blue {
    background: $status-blue;
  }

  &-grey {
    background: $status-grey;
  }

  &-light-salmon {
    background: $status-light-salmon;
  }

  &-green {
    background: $status-green;
  }

  &-red {
    background: $status-red;
  }
}

.hocLink {
  color: $hyperlink-blue;
  text-decoration: underline;
}

/* Scrollbar styling */

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 1px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba($table-border-grey, 1); // $white;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $grey-semi-dark;
}

.introjs-tooltipReferenceLayer {
  z-index: 99999999 !important;
}

// new styling end